import React, { useState, useEffect } from 'react';
import Layout from 'Screens/Dashboard/components/Layout';
import ImportFileCreationModal from 'Screens/ManageEditCreate/components/ImportFileCreationModal';
import { getTopHosts, getVulnsCount } from 'Screens/Dashboard/actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';

import EmptyVulns from 'Common/Components/EmptyVulns';
import emptyFeed from 'Images/empty-feed.png';
import ManualCreationModal from 'Screens/ManageEditCreate/components/ManualCreationModal';

import { StyledCol, Wrapper } from './Dashboard/styled';
import { getTotalVulns } from 'Screens/Workspaces/actions/Actions';
import { selectIsFetching, selectReloadVulnerabilities, selectTools, selectTotalVulns } from 'store/Dashboard/selectors';

const DashboardLayout = () => {
  const dispatch = useDispatch();
  const tools = useSelector(selectTools);
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const reloadVulnerabilities = useSelector(selectReloadVulnerabilities);
  const totalVulns = useSelector(selectTotalVulns);
  const isFetching = useSelector(selectIsFetching);

  useEffect(() => {
    dispatch(getVulnsCount());
    dispatch(getTotalVulns(workspaceSelected));
    dispatch(getTopHosts());
  }, [workspaceSelected, reloadVulnerabilities, dispatch]);

  const [showModalUploadVulns, toggleModalUploadVulns] = useState(false);
  const [showModalCreateVuln, toggleModalCreateVuln] = useState(false);

  if ((totalVulns === 0) && !isFetching) {
    return (
      <>
        <EmptyVulns image={ emptyFeed } toggleModalUploadVulns={ () => toggleModalUploadVulns(true) } toggleModalCreateVuln={ () => toggleModalCreateVuln(true) } />
        <ImportFileCreationModal show={ showModalUploadVulns } handleClose={ () => toggleModalUploadVulns(false) } />
        <ManualCreationModal show={ showModalCreateVuln } handleClose={ () => toggleModalCreateVuln(false) } />
      </>
    );
  }

  return (
    <Wrapper>
      <StyledCol>
        { tools.filter((t) => t.render).length > 0 ? <Layout tools={ tools } /> : null }
      </StyledCol>
    </Wrapper>
  );
};

export default DashboardLayout;
