/* eslint-disable react/destructuring-assignment */
import React from 'react';
import get from 'lodash/get';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';
import handleLeftClick from 'Common/Components/CustomReactTable/leftClickHelper';
import {
  ContextMenuTrigger
} from 'react-contextmenu';
import ContextMenuWorkspaceMassiveUpdate from 'Screens/Workspaces/components/ContextMenuWorkspaceMassiveUpdate';
import {
  changeReadOnly,
  selectWorkspace,
  setOrderByWorkspaces
} from 'Screens/Workspaces/actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsFetching, selectLastIndexForRange, selectLastSelected, selectLastWorkspaceSelected, selectPermissions, selectWorkspacesOrdered, selectWorkspacesSelected
} from 'store/Workspace/selectors';
import { TableWrapper } from './styled';
import getColumns from './Columns';
import { FormattedMessage } from 'react-intl';

const Table = ({ setShowConfirmation, setShowWarning }) => {
  const dispatch = useDispatch();
  const workspacesSelected = useSelector(selectWorkspacesSelected);
  const lastWorkspaceSelected = useSelector(selectLastWorkspaceSelected);
  const lastIndexForRange = useSelector(selectLastIndexForRange);
  const lastSelected = useSelector(selectLastSelected);
  const workspaceList = useSelector(selectWorkspacesOrdered);
  const isFetching = useSelector(selectIsFetching);
  const permissions = useSelector(selectPermissions);
  const canUpdate = get(permissions, 'update.allowed', true);

  const getSorting = (sorted) => {
    const vulnsSortIndex = sorted.findIndex((s) => s.id === 'vulnerabilities');

    if (vulnsSortIndex >= 0) {
      const desc = sorted[vulnsSortIndex].desc;
      const sortTotalCount = { id: 'vulnerability_total_count', desc };

      const newSorting = [...sorted];
      newSorting.splice(vulnsSortIndex, 1, sortTotalCount);
      return newSorting;
    }
    return sorted;
  };

  const changeReadOnlyStatus = (ws, readonly) => dispatch(changeReadOnly(ws, readonly));

  const handleClick = (e, rowInfo) => {
    const selected = workspacesSelected.filter((x) => x.id === rowInfo.original.id).length > 0;

    const leftClickResponse = handleLeftClick(e, rowInfo, '', selected, workspaceList, workspacesSelected, lastWorkspaceSelected, lastIndexForRange, lastSelected);
    if (leftClickResponse) dispatch(selectWorkspace(leftClickResponse));
  };

  const getTrProps = (state, rowInfo) => {
    const selected = workspacesSelected.filter((x) => x.id === rowInfo.original.id).length > 0;

    return {
      onClick: (e) => {
        handleClick(e, rowInfo);
        return true;
      },
      onContextMenu: () => {
        if (!selected) dispatch(selectWorkspace({ newRowsSelected: [rowInfo.original] }));
        return false;
      },
      style: {
        background: selected ? '#edf2f7' : 'transparent'
      }
    };
  };

  const fetchData = (state) => {
    const { sorted } = state;
    if (!isFetching) {
      const sorting = getSorting(sorted);
      dispatch(setOrderByWorkspaces(sorting));
    }
  };

  const mockData = [...Array(13).keys()];

  return (
    <TableWrapper>
      <ContextMenuTrigger id="massive_update_workspace_table" holdToDisplay={ 1000000 }>
        <CustomReactTable
          data={ isFetching ? mockData : workspaceList }
          columns={ getColumns(changeReadOnlyStatus, handleClick, isFetching, setShowConfirmation, setShowWarning) }
          minRows={ 0 }
          style={ { height: 'calc(100vh - 158px)', boxShadow: '-8px 14px 20px 0px rgba(0, 0, 0, 0.03)' } }
          onFetchData={ fetchData }
          manual
          getTrProps={ getTrProps }
          showPagination={ false }
          noDataText={ <FormattedMessage id="app.nodata" /> }
        />
      </ContextMenuTrigger>
      { canUpdate
        ? <ContextMenuWorkspaceMassiveUpdate trigger="massive_update_workspace_table" onShow={ () => { } } />
        : null }
    </TableWrapper>
  );
};

export default Table;
