import React, { useState, useRef } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showModalImportDashboard } from 'Screens/Dashboard/actions/Actions';
import { FormattedMessage } from 'react-intl';
import ModalCreation from 'Screens/Workspaces/components/ModalCreation';
import { PropTypes } from 'prop-types';
import { saveWorkspaceHistory } from 'Screens/Faraday/actions/Actions';
import selectModal from 'store/modals/selectors';
import { closeModal, openModal } from 'store/modals/actions';
import { selectCurrentWorkspace, selectFaradayLoading, selectIsAdmin } from 'store/Faraday/selectors';
import useClickOutside from 'Hooks/useClickOutside';
import replace from 'lodash/replace';
import get from 'lodash/get';
import { MODAL_WORKSPACE_CREATE_EDIT } from 'store/modals/modals';
import { redirect } from 'store/Router/actions';
import { selectLocation } from 'store/Router/selectors';
import { getWorkspaceStats, getWorkspacesForSelector, setWsStatsError } from 'Screens/Workspaces/actions/Actions';
import { clearFilters, setPageNumber } from 'store/Filters/actions';
import { newGetVulns } from 'store/Manage/filterActions';
import {
  Wrapper, Toggle, Menu, Item, WorkspaceSelectedName,
  ArrowDown, WorkspaceOptionName, ActionsContainer,
  ViewAll, CreateNew, WorkspaceVulns, NoItems
} from './styled';

import { selectWorkspacesForSelector } from 'store/Workspace/selectors';
import api from 'services/api';
import SpinnerComponent from 'Common/Components/SpinnerContainer';

const WorkspaceSelector = ({
  saveWorkspaceHistoryProp, workspaces, workspaceSelected
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const noWorkspaces = workspaces.length === 0;
  const showNoItem = noWorkspaces || (workspaces.length === 1 && workspaces[0].name === workspaceSelected);
  const dropdownRef = useRef();
  useClickOutside(dropdownRef, () => setShowDropdown(false));
  const location = useSelector(selectLocation);
  const isAdmin = useSelector(selectIsAdmin);
  const isManage = get(location, 'pathname', '').includes('/manage');
  const isAssets = get(location, 'pathname', '').includes('/host');
  const isDashboard = get(location, 'pathname', '').includes(`/feed/${workspaceSelected}`);
  const isFetching = useSelector(selectFaradayLoading);

  const redirectTo = (ws) => {
    let url = '';
    if (location.pathname === '/404') {
      url = `/feed/${ws.name}`;
    } else {
      url = replace(location.pathname, workspaceSelected, ws.name);
    }
    dispatch(redirect(`${url}`));
  };

  const selectWorkspace = async (ws) => {
    saveWorkspaceHistoryProp(ws.name);
    if (!isDashboard) {
      try {
        const response = await api.workspace.getWsStats(ws.name);
        dispatch(getWorkspaceStats(response));
      } catch (error) {
        dispatch(setWsStatsError(error.message));
      }
    }
    if (ws.stats.total_vulns === 0) dispatch(showModalImportDashboard(true));
    redirectTo(ws);
    if (isManage) {
      dispatch(setPageNumber('vulns', 1));
      dispatch(newGetVulns());
    }
    if (isAssets) dispatch(setPageNumber('assets', 1));
  };

  const getItems = () => {
    if (isFetching) return <SpinnerComponent />;
    if (showNoItem) {
      return (
        <NoItems><FormattedMessage id="workspaces.contextMenu.no_other" /></NoItems>
      );
    }

    return workspaces.map((ws, index) => {
      if (ws.name !== workspaceSelected) {
        return (
          <Item id={ `ws-selector-${ws.name}` } onClick={ () => { selectWorkspace(ws); setShowDropdown(false); dispatch(clearFilters('vulns')); return false; } } key={ ws.name } lastelement={ index + 1 === workspaces.length }>
            <WorkspaceOptionName>{ws.name}</WorkspaceOptionName>
            <WorkspaceVulns>{ws.stats.total_vulns}</WorkspaceVulns>
          </Item>
        );
      } return null;
    });
  };

  const show = useSelector((state) => selectModal(state, MODAL_WORKSPACE_CREATE_EDIT));
  const handleClose = () => dispatch(closeModal(MODAL_WORKSPACE_CREATE_EDIT));
  const handleOpen = () => dispatch(openModal(MODAL_WORKSPACE_CREATE_EDIT));
  const handleToggle = () => {
    setShowDropdown(!showDropdown);
    if (!showDropdown) {
      dispatch(getWorkspacesForSelector());
    }
  };
  return (
    <Wrapper ref={ dropdownRef }>
      <Toggle onClick={ handleToggle }>
        <WorkspaceSelectedName id={ `ws-selector-selected-${workspaceSelected}` } title={ workspaceSelected || 'No Workspace Selected' }>
          {workspaceSelected || 'No Workspace Selected'}
        </WorkspaceSelectedName>
        <ArrowDown />
      </Toggle>
      { workspaces.length >= 0 && showDropdown && (
        <Menu>
          {getItems()}
          <ActionsContainer>
            {!noWorkspaces && <ViewAll onClick={ () => { setShowDropdown(false); dispatch(redirect('/workspaces')); } }><FormattedMessage id="workspaces.contextMenu.viewAll" /></ViewAll>}
            { isAdmin && <CreateNew onClick={ () => { setShowDropdown(false); handleOpen(); } }><FormattedMessage id="workspaces.contextMenu.createNew" /></CreateNew> }
          </ActionsContainer>
        </Menu>
      ) }
      {show && <ModalCreation show handleClose={ handleClose } /> }
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  workspaces: selectWorkspacesForSelector(state),
  workspaceSelected: selectCurrentWorkspace(state)
});

const mapDispatchToProps = (dispatch) => ({
  saveWorkspaceHistoryProp: (ws) => {
    dispatch(saveWorkspaceHistory(ws));
  }
});

WorkspaceSelector.propTypes = {
  workspaces: PropTypes.instanceOf(Object).isRequired,
  workspaceSelected: PropTypes.string.isRequired,
  saveWorkspaceHistoryProp: PropTypes.func.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspaceSelector));
