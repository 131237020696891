import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFilter, removeFilter, storeDashboardFilter } from 'store/Filters/actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { redirect } from 'store/Router/actions';
import { CLOSED_FILTERS, CONFIRMED_FLAG_FILTERS, DASHBOARD_FILTERS } from 'store/Filters/constants';
import Checkbox from 'Common/Components/Checkbox';
import { isFilteringBy, selectStoredDashboardFilter } from 'store/Filters/selectors';
import { getStatsFiltered } from 'Screens/Dashboard/actions/Actions';
import get from 'lodash/get';
import {
  WrapperSeverity, Title, Wrapper, Empty, Severity, Header, FilterIcon, FilterWrapper, Dropdown, Selector, Option, ArrowDown
} from './styled';
import { selectIsFetching } from 'store/Dashboard/selectors';
import { BoxSummary } from '../../Layout/styled';
import Summary from '../Summary';
import SpinnerComponent from 'Common/Components/SpinnerContainer';

const Filter = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { confirmed } = CONFIRMED_FLAG_FILTERS;
  const { closed } = CLOSED_FILTERS;
  const storeFilters = useSelector(selectStoredDashboardFilter);

  const isFilteringByConfirm = useSelector((state) => isFilteringBy(state, 'vulns', confirmed));
  const isFilteringByClosed = useSelector((state) => isFilteringBy(state, 'vulns', closed));

  useEffect(() => {
    if (storeFilters.confirmed)dispatch(addFilter('vulns', confirmed));
    if (storeFilters.closed)dispatch(addFilter('vulns', closed));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmedFilterToggle = () => {
    dispatch(storeDashboardFilter('confirmed', !isFilteringByConfirm));
    if (isFilteringByConfirm) dispatch(removeFilter('vulns', confirmed));
    else dispatch(addFilter('vulns', confirmed));
  };
  const closedFilterToggle = () => {
    dispatch(storeDashboardFilter('closed', !isFilteringByClosed));
    if (isFilteringByClosed) dispatch(removeFilter('vulns', closed));
    else dispatch(addFilter('vulns', closed));
  };

  const content = () => {
    if (isFilteringByConfirm && isFilteringByClosed) return 'Confirmed, Not Closed';
    if (isFilteringByConfirm) return 'Confirmed';
    if (isFilteringByClosed) return 'Not Closed';
    return 'Showing All';
  };

  return (
    <FilterWrapper>
      <FilterIcon onClick={ () => setIsOpen(!isOpen) } $active={ isFilteringByConfirm || isFilteringByClosed } />
      <Dropdown onClick={ () => setIsOpen(!isOpen) } >
        {content()}
        <ArrowDown />
      </Dropdown>
      {isOpen && (
      <Selector>
        <Option onClick={ confirmedFilterToggle }>
          <Checkbox state={ isFilteringByConfirm } />
          Confirmed
        </Option>
        <Option onClick={ closedFilterToggle } >
          <Checkbox state={ isFilteringByClosed } />
          Not Closed
        </Option>
      </Selector>
      ) }
    </FilterWrapper>
  );
};

const Vulnerabilities = () => {
  const dispatch = useDispatch();
  const stats = useSelector((state) => get(state, 'dashboard.tools[3].data.stats', {}));
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const isLoadingVulns = useSelector(selectIsFetching);

  const types = [
    { name: 'critical', id: 'critical_vulns' },
    { name: 'high', id: 'high_vulns' },
    { name: 'medium', id: 'medium_vulns' },
    { name: 'low', id: 'low_vulns' },
    { name: 'informational', id: 'info_vulns' }
  ];

  const groups = types.map((type) => {
    if (!stats) return null;

    const newType = { count: stats[type.id], name: type.name, severity: type.name };
    // eslint-disable-next-line consistent-return
    return newType;
  });

  const totalCount = groups ? groups.reduce((prev, curr) => prev + curr.count, 0) : 0;

  const { confirmed } = CONFIRMED_FLAG_FILTERS;
  const { closed } = CLOSED_FILTERS;

  const isFilteringByConfirm = useSelector((state) => isFilteringBy(state, 'vulns', confirmed));
  const isFilteringByClosed = useSelector((state) => isFilteringBy(state, 'vulns', closed));

  const filterArr = useMemo(() => {
    const confirmed = '{"name":"confirmed","op":"eq","val":"true"}';
    const closed = '{"name":"status","op":"neq","val":"closed"}';
    const arr = [];

    if (isFilteringByConfirm) arr.push(confirmed);
    if (isFilteringByClosed) arr.push(closed);
    return `[${arr.toString()}]`;
  }, [isFilteringByConfirm, isFilteringByClosed]);

  const rootManage = (severity) => {
    const filterBySeverity = DASHBOARD_FILTERS.vulnerabilitiesBySeverity(severity);
    const filterByConfirmed = isFilteringByConfirm ? CONFIRMED_FLAG_FILTERS.confirmed : CONFIRMED_FLAG_FILTERS.notConfirmed;

    dispatch(addFilter('vulns', filterBySeverity));
    if (isFilteringByConfirm) dispatch(addFilter('vulns', filterByConfirmed));
    if (isFilteringByClosed) dispatch(addFilter('vulns', CLOSED_FILTERS.closed));
    dispatch(redirect(`/manage/${workspaceSelected}`));
  };

  useEffect(() => {
    if (isFilteringByConfirm && isFilteringByClosed) {
      dispatch(getStatsFiltered('confirmed=true&only_opened=true'));
    }
    if (isFilteringByConfirm && !isFilteringByClosed) {
      dispatch(getStatsFiltered('confirmed=true'));
    }
    if (isFilteringByClosed && !isFilteringByConfirm) {
      dispatch(getStatsFiltered('only_opened=true'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterArr, workspaceSelected]);

  return (
    <>
      { isLoadingVulns
        ? <SpinnerComponent height={ '250px' } />
        : <BoxSummary>
            <Wrapper>
              <Header>
                <Title>Vulnerabilities by Severity</Title>
                <Filter />
              </Header>
              {groups
                ? (
                  <WrapperSeverity>
                    {groups.map((group) => <Severity type={ group.severity } onClick={ () => { rootManage(group.severity); } } width={ totalCount ? group.count / totalCount : 1000 } key={ group.severity }>{group.count}</Severity>)}
                  </WrapperSeverity>
                  )
                : <Empty>No vulnerabilities has been found yet.</Empty>}
            </Wrapper>
            <Summary />
          </BoxSummary>
      }
    </>
  );
};

export default Vulnerabilities;
