/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './App.scss';
import './Styles/bootstrap.min.css';
import './Styles/styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import Error from 'Screens/Error';
import { validateSession } from 'Screens/Login/actions/Actions';
import { selectLocation } from 'store/Router/selectors';
import { selectIsLoggedIn, selectIsValidatingSession } from 'store/Sesion/selectors';
import { setUrlReturn } from 'Screens/Faraday/actions/Actions';
import NavMenu from 'Screens/NavMenu';
import SideBar from 'Screens/Sidebar';

import errorHandler from './Common/Handlers/ErrorHandler';
import FileUploadContext from './Common/Components/FileUploadContext';
import CommandValidator from './Common/Components/CommandValidator';
import RedirectModal from './Common/Components/RedirectModal';
import { Content, Wrapper } from './styled';
import { selectShowProcessingQueue } from 'store/FileUploaderContext/selector';

const App = ({ logOut, children }) => {
  const location = useSelector(selectLocation);
  const isValidatingSession = useSelector(selectIsValidatingSession);
  const showImportModal = useSelector(selectShowProcessingQueue);
  const loggedIn = useSelector((state) => selectIsLoggedIn(state));

  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    dispatch(setUrlReturn(location.pathname));
    dispatch(validateSession());
    errorHandler(logOut);
    setMounted(true);
  }, [dispatch]);

  if (!mounted || isValidatingSession) return null;

  return (
    <Wrapper>
      <NavMenu />
      <SideBar />
      <Content>
        { children }
      </Content>
      <RedirectModal />
      <Error />
      { (showImportModal && loggedIn) && <FileUploadContext /> }
      <CommandValidator />
    </Wrapper>
  );
};

export default App;
