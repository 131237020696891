// SpinnerComponent.jsx
import React from 'react';
import styled, { keyframes } from 'styled-components';

// Keyframes for the spin animation
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Styled component for the spinner
const Spinner = styled.div`
  width: 3em; // Using em units for relative sizing
  height: 3em; // Using em units for relative sizing
  border: 0.4em solid transparent;
  border-top-color: #0082ed;
  border-bottom-color: #0082ed;
  border-radius: 50%;
  animation: ${spin} 1.5s ease-in-out infinite; // Adjusted duration and timing function
`;

// Styled component for the spinner container
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height:${props => props.height || '100%'};
`;

const SpinnerComponent = ({ height }) => {
  return (
    <SpinnerContainer height={ height }>
      <Spinner aria-hidden="true" />
    </SpinnerContainer>
  );
};

export default SpinnerComponent;
